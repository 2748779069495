







































import {Component, Vue} from 'vue-property-decorator';
import {Action, State} from 'vuex-class';
import _ from 'lodash';
import VueMarkdown from 'vue-markdown';
import { IApp } from '@/interfaces';

@Component({
  name: 'Footer',
  components: { VueMarkdown }
})
export default class Footer extends Vue {
  @State private cms: any;
  @State private app!: IApp;
  @Action('cms/loadTheme') private loadTheme: any;
  private footerContent = null;

  get footerLogoDimensions() {
    return {
      maxHeight: _.get(this.cms, 'theme.footerLogoDimensions.maxHeight', 35),
      maxWidth: _.get(this.cms, 'theme.footerLogoDimensions.maxWidth', 90),
    };
  }

  get logo() {
    return _.get(this.cms, 'theme.footerLogo') || _.get(this.cms, 'theme.logo');
  }

  private created() {
    this.loadTheme(_.get(this.$route, 'params.targetSegment'));
    this.getFooterData();
  }

  private async getFooterData() {
    const baseFooter = await import(`@/settings/footer/base.md`).catch((error: any) => {
      console.error('missing base footer config', error);
    });
    const footerTargetSegment = await import(`@/settings/footer/${this.app.targetSegment}/${this.app.language}.md`).catch((error: any) => {
      console.info('no target segment footer config use the base footer instead');
    });
    this.footerContent = footerTargetSegment ? footerTargetSegment.default : baseFooter.default;
  }
}
